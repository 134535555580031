import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  user;

  @Input() titulo: string = '';
  @Input() type: string = 'standard';

  constructor(
    private authService: AuthService,
    private location: Location,
    private router: Router,
  ) {}

  async ionViewWillEnter() {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
  }

  perfil() {
    this.router.navigate(['/tabs/usuario-perfil']);
  }

  back() {
    this.location.back();
  }

}
