/* circular-progress.component.ts */

import { Component, Input, Renderer2, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent {

  // Set the progress value (0 to 100)
  @Input() progress = 0;
  @Input() progressSide = 'rtl';
  @Input() showProgressText = false;

  ngOnChanges() {
    this.updateProgressBar();
  }

  getOffset() {
    if (this.progressSide == 'rtl') return 100 - (this.progress / 100) * 100;

    return (this.progress / 100) * 100;
  }

  updateProgressBar() {
    // Get the progress circle and text elements
    const progressCircle = document.getElementById('progress');
    const progressText = document.getElementById('progressText');

    // Calculate the dash offset based on the progress value
    const offset = this.getOffset();

    // Apply the dash offset to the progress circle
    progressCircle.style.background = `conic-gradient(
      var(--ion-color-primary) ${offset}%,
      #ecf0f1 ${offset}%,
      #ecf0f1 100%
    )`;

    // Update the progress text
    if (progressText) progressText.textContent = `${this.progress}%`;
  }
}
