import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const API = environment.api;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  key: any;
  token: any;

  @Output() updateUserInfo: EventEmitter<any> = new EventEmitter();

  protected userInfo;

  constructor(
    private storageService: StorageService,
    private navController: NavController,
    private navCtrl: NavController,
    private http: HttpClient,
  ) {}

  login(data) {
    return new Promise((resolve) => {
      this.http
        .post(`${API}/auth/login`, data, { observe: 'response' })
        .subscribe(
          async (response: any) => {
            // Guarda el token de acceso
            await this.storageService.set('token', response.body.access_token);
            this.getUser();
            return resolve(response);
          },
          (error) => resolve(error)
        );
    });
  }

  register(data) {
    return new Promise((resolve) => {
      this.http
        .post(`${API}/auth/register`, data, { observe: 'response' })
        .subscribe(
          (response: any) => {
            // Guarda el token de acceso
            // if (response.body.access_token) this.storageService.set('token', response.body.access_token);
            return resolve(response);
          },
          (error) => resolve(error)
        );
    });
  }

  async getUser() {
    if (!this.userInfo) {
      await this.user();
    }

    return this.userInfo ? { ...this.userInfo } : null;
  }

  // Datos del usuario
  async user(fromGuard = false): Promise<boolean> {
    const token = await this.storageService.get('token');

    if (!token && fromGuard) {
      this.navController.navigateRoot('/login');
      return Promise.resolve(false);
    }

    return new Promise((resolve) => {
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      this.http
        .get(`${API}/auth/user`, { headers, observe: 'response' })
        .subscribe(
          (response: any) => {
            if (response.ok) {
              this.userInfo = response.body;
              this.updateUserInfo.emit(this.userInfo);
              return resolve(true);
            }

            return resolve(false);
          },
          (error) => resolve(false)
        );
    });
  }

  checkPasswordToken(token) {
    return new Promise(resolve => {
      this.http.get(`${API}/password/find/${token}`)
      .subscribe(
        response => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  createPasswordToken(data) {
    return new Promise(resolve => {
      this.http.post(`${API}/password/create`, data)
      .subscribe(
        response => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  resetPassword(data) {
    return new Promise(resolve => {
      this.http.post(`${API}/password/reset`, data)
      .subscribe(
        response => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  async logout() {
    this.token = null;
    this.userInfo = null;
    // await this.getUser();
    this.storageService.remove('token');
    this.updateUserInfo.emit(this.userInfo);
    this.navCtrl.navigateRoot('/login', {animated: true});
  }
}
