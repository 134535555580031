
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-emom-timer',
  templateUrl: './emom-timer.component.html',
  styleUrls: ['./emom-timer.component.scss'],
})
export class EmomTimerComponent implements OnInit, OnDestroy {

  totalRounds: number = 0;
  exerciseTimeInMinutes: number = 0; // Exercise time in minutes
  exerciseTimeToAddSeconds: number = 0; // Exercise time to add in seconds
  restTimeInSeconds: number = 0; // Rest time in seconds
  timeInSeconds: number = 0;
  timer: any;
  displayTime: string;
  inputRounds: number = 0;
  inputExerciseMinutes: number = 0;
  inputExerciseSecondsToAdd: number = 0;
  inputRestDuration: number = 0;
  timerStarted: boolean = false;
  countdown: number = 10; // Initial countdown in seconds
  currentRound: number = 0;
  exercisePhase: boolean = true; // True for exercise, false for rest
  emomStarted: boolean = false; // New property to track Emom timer start

  @Output() timeFinished = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  startCountdown() {
    this.timerStarted = true; // Set timerStarted to true
    this.emomStarted = false;
    this.timer = setInterval(() => {
      if (this.countdown >= 0) {
        this.displayTime = this.countdown.toString();
        this.countdown--;
        this.timeInSeconds = this.countdown * 10;
        this.exerciseTimeInMinutes = this.inputExerciseMinutes;
        this.exerciseTimeToAddSeconds = this.inputExerciseSecondsToAdd;
      } else {
        clearInterval(this.timer);
        this.startTimer();
      }
    }, 1000);
  }

  startTimer() {
    if (this.validateInput()) {
      this.totalRounds = this.inputRounds;
      this.timerStarted = true;
      this.emomStarted = true;
      this.exerciseTimeInMinutes = this.inputExerciseMinutes;
      this.exerciseTimeToAddSeconds = this.inputExerciseSecondsToAdd;
      this.restTimeInSeconds = this.inputRestDuration;
      this.currentRound = 1; // Start from the first round
      this.timeInSeconds = this.exerciseTimeInMinutes * 60 + this.exerciseTimeToAddSeconds;
      this.displayTime = this.exercisePhase
        ? this.getFormattedTime(this.timeInSeconds)
        : this.getFormattedTime(this.restTimeInSeconds);
      this.initTimer();
    } else {
      alert('Please enter valid values for rounds and exercise duration.');
    }
  }

  validateInput(): boolean {
    return (
      this.inputRounds > 0 &&
      this.inputExerciseMinutes >= 0 &&
      this.inputExerciseSecondsToAdd >= 0 &&
      this.inputRestDuration >= 0
    );
  }

  initTimer() {
    this.timeInSeconds = this.exercisePhase
      ? this.exerciseTimeInMinutes * 60 + this.exerciseTimeToAddSeconds
      : this.restTimeInSeconds;
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  updateTime() {
    if (this.timeInSeconds > 0) {
      this.timeInSeconds--;
      this.displayTime = this.getSecondsAsDigitalClock(this.timeInSeconds);
      if (this.timeInSeconds === 0) {
        this.switchPhase();
        this.startNextInterval();
      }
    }
  }

  switchPhase() {
    this.exercisePhase = !this.exercisePhase;
  }

  startNextInterval() {
    // Move to the next round if not the last round during exercise phase
    if (this.exercisePhase) {
      if (this.currentRound < this.totalRounds) {
        this.currentRound++;
      } else {
        // Timer completed, you can add logic here
        clearInterval(this.timer);
        this.timeFinished.emit();
      }
    }

    // Set the display and timer for the next phase
    const totalTimeInSeconds = this.exerciseTimeInMinutes * 60 + this.exerciseTimeToAddSeconds;
    this.displayTime = this.exercisePhase
      ? this.getFormattedTime(totalTimeInSeconds)
      : this.getFormattedTime(this.restTimeInSeconds);
    this.timeInSeconds = this.exercisePhase
      ? totalTimeInSeconds
      : this.restTimeInSeconds;
  }

  getSecondsAsDigitalClock(inputSeconds: number): string {
    const minutes = Math.floor(inputSeconds / 60);
    const seconds = inputSeconds % 60;
    return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  padNumber(num: number) {
    return num < 10 ? '0' + num : num.toString();
  }

  getFormattedTime(inputSeconds: number): string {
    const minutes = Math.floor(inputSeconds / 60);
    const seconds = inputSeconds % 60;
    return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }

  getProgressPercentage(): number {
    return (this.timeInSeconds / (this.exerciseTimeInMinutes * 60 + this.exerciseTimeToAddSeconds)) * 100;
  }

  finish() {
    clearInterval(this.timer);
    this.timeFinished.emit();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
