// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  width: 200px;
  height: 200px;
  position: relative;
}

.progress-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: conic-gradient(var(--ion-color-primary) 0%, var(--ion-color-primary) 0%, #ecf0f1 0%, #ecf0f1 100%);
  border-radius: 50%;
}

.progress-content {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  background: var(--ion-color-secondary);
}

.progress-text {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2c3e50;
}`, "",{"version":3,"sources":["webpack://./src/app/components/circular-progress/circular-progress.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,8GAAA;EAMA,kBAAA;AAJJ;;AAQA;EACI,UAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;AALJ;;AAQA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;AALJ","sourcesContent":[".progress-container {\n    width: 200px;\n    height: 200px;\n    position: relative;\n}\n\n.progress-circle {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    background: conic-gradient(\n        var(--ion-color-primary) 0%,\n        var(--ion-color-primary) 0%,\n        #ecf0f1 0%,\n        #ecf0f1 100%\n    );\n    border-radius: 50%;\n    // position: absolute;\n}\n\n.progress-content {\n    width: 95%;\n    height: 95%;\n    border-radius: 50%;\n    background: var(--ion-color-secondary);\n}\n\n.progress-text {\n    font-size: 24px;\n    font-weight: bold;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: #2c3e50;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
