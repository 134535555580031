import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tabs/ejercicios'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/auth/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'recuperar-contrasenha',
    loadChildren: () => import('./pages/auth/recuperar-contrasenha/recuperar-contrasenha.module').then( m => m.RecuperarContrasenhaPageModule)
  },
  {
    path: 'cambiar-contrasenha/:token',
    loadChildren: () => import('./pages/auth/cambiar-contrasenha/cambiar-contrasenha.module').then( m => m.CambiarContrasenhaPageModule)
  },
  {
    path: 'marcas',
    loadChildren: () => import('./pages/marcas/marcas.module').then( m => m.MarcasPageModule)
  },
  {
    path: 'asistencia',
    loadChildren: () => import('./pages/asistencia/asistencia.module').then( m => m.AsistenciaPageModule)
  },
  {
    path: 'rutina',
    loadChildren: () => import('./pages/rutina/rutina.module').then( m => m.RutinaPageModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/admin/noticias',
        pathMatch: 'full'
      },
      {
        path: 'arqueo',
        loadChildren: () => import('./pages/admin/arqueo/arqueo.module').then( m => m.ArqueoPageModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/admin/user/user.module').then(m => m.AdminUserPageModule)
      },
      {
        path: 'noticias',
        loadChildren: () => import('./pages/admin/noticias/noticias.module').then(m => m.AdminNoticiasPageModule)
      },
      {
        path: 'ejercicios',
        loadChildren: () => import('./pages/admin/ejercicios/ejercicios.module').then(m => m.AdminEjerciciosPageModule)
      },
      {
        path: 'ejercicio',
        loadChildren: () => import('./pages/admin/ejercicio/ejercicio.module').then( m => m.AdminEjercicioPageModule)
      },
      {
        path: 'ejercicio/:id',
        loadChildren: () => import('./pages/admin/ejercicio/ejercicio.module').then( m => m.AdminEjercicioPageModule)
      },
      {
        path: 'categorias',
        loadChildren: () => import('./pages/admin/categorias/categorias.module').then( m => m.AdminCategoriasPageModule)
      },
      {
        path: 'categoria',
        loadChildren: () => import('./pages/admin/categoria/categoria.module').then( m => m.AdminCategoriaPageModule)
      },
      {
        path: 'categoria/:id',
        loadChildren: () => import('./pages/admin/categoria/categoria.module').then( m => m.AdminCategoriaPageModule)
      },
      {
        path: 'suscripciones',
        loadChildren: () => import('./pages/admin/suscripciones/suscripciones.module').then( m => m.AdminSuscripcionesPageModule)
      },
      {
        path: 'suscripcion',
        loadChildren: () => import('./pages/admin/suscripcion/suscripcion.module').then( m => m.AdminSuscripcionPageModule)
      },
      {
        path: 'suscripcion/:id',
        loadChildren: () => import('./pages/admin/suscripcion/suscripcion.module').then( m => m.AdminSuscripcionPageModule)
      },
      {
        path: 'marcas',
        loadChildren: () => import('./pages/admin/marcas/marcas.module').then( m => m.AdminMarcasPageModule)
      },
      {
        path: 'rutinas',
        loadChildren: () => import('./pages/admin/rutinas/rutinas.module').then( m => m.AdminRutinasPageModule)
      },
      {
        path: 'rutina',
        loadChildren: () => import('./pages/admin/rutina/rutina.module').then( m => m.AdminRutinaPageModule)
      },
      {
        path: 'rutina/:id',
        loadChildren: () => import('./pages/admin/rutina/rutina.module').then( m => m.AdminRutinaPageModule)
      },
      {
        path: 'horarios',
        loadChildren: () => import('./pages/admin/horarios/horarios.module').then( m => m.HorariosPageModule)
      },
      {
        path: 'reservas',
        loadChildren: () => import('./pages/admin/reservas/reservas.module').then( m => m.ReservasPageModule)
      },
      {
        path: 'pagos',
        loadChildren: () => import('./pages/admin/pagos/pagos.module').then( m => m.PagosPageModule)
      },
      {
        path: 'configuraciones',
        loadChildren: () => import('./pages/admin/configs/configs.module').then( m => m.ConfigsPageModule)
      }
    ]
  },
  {
    path: 'smartwod',
    loadChildren: () => import('./pages/smartwod/smartwod.module').then( m => m.SmartwodPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
