import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-unpaid',
  templateUrl: './unpaid.component.html',
  styleUrls: ['./unpaid.component.scss'],
})
export class UnpaidComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
  ) { }

  ngOnInit() {}

  confirm() {
    this.authService.logout();
    this.modalController.dismiss();
  }

}
