import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';

import { GeneralService } from './services/general.service';
import { MenuService } from './services/menu.service';
import { AuthService } from 'src/app/services/auth.service';

import { UnpaidComponent } from './components/unpaid/unpaid.component';

import { register } from 'swiper/element/bundle';

import { Observable, Subscription } from 'rxjs';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  routerHidden = true;
  showingUnpaidModal = false;
  @ViewChild('splash', {static: false}) splash: ElementRef;

  public menuItems: Observable<any>;
  public logged: boolean = false;
  public user;

  subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private generalService: GeneralService,
    private authService: AuthService,
    private menuService: MenuService,
    private platform: Platform,
    private location: Location,
  ) {
    this.initializeApp();
  }

  ngOnInit(){
    this.userData();
    this.menuItems = this.menuService.getMenuItems();

    const unpaid = this.generalService.unpaid.subscribe(() => this.showUnpaidModal());

    this.subscriptions.push(unpaid);
  }

  initializeApp() {
    (async () => await SplashScreen.hide())();
    this.platform.ready().then(async () => {

      setTimeout(() => {
        this.routerHidden = false;
        this.splash.nativeElement.style.display = 'none';
      }, 3000);
    });
  }

  ngAfterViewInit() {
    const userInfo = this.authService.updateUserInfo
    .subscribe(user => {
      this.user = user;
      this.logged = (user) ? true : false;
    });

    this.platform.backButton
    .subscribe(() => {
      this.location.back();
    })

    this.subscriptions.push(userInfo);
  }

  hideForClients(item) {
    const validRoleLevels = [1, 2]; // Admin - Gestor
    return (this.logged && item.admin && this.user && this.user.roles && !validRoleLevels.includes(this.user.roles.nivel));
  }

  async userData() {
    this.user = await this.authService.getUser();
    this.logged = !!this.user;
  }

  async showUnpaidModal() {
    if (this.showingUnpaidModal) return;

    this.showingUnpaidModal = true;

    const modal = await this.modalController.create({
      component: UnpaidComponent,
      showBackdrop: true
    });

    await modal.present();

    modal.onDidDismiss().then(() => this.showingUnpaidModal = false).catch(() => this.showingUnpaidModal = false);
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe());
  }
}
