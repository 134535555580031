import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {

  date = {
    from: moment().subtract(7, 'd').format(),
    to: moment().format()
  };

  multiple = true;
  selection: String[];

  @Output() selectionChanged = new EventEmitter();
  @ViewChild(IonPopover, { static: false }) ionPopover: IonPopover;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.selectionChanged.emit(this.date);
  }

  dateTimeChanged(event) {
    const { detail } = event;
    if (this.multiple) {
      if (detail.value && detail.value.length == 2) {
        this.selection = null;
        this.date = {
          from: detail.value[0],
          to: detail.value[1]
        };
        this.selectionChanged.emit(this.date);
        this.ionPopover.dismiss();
      }
      return;
    }

    this.ionPopover.dismiss();
    this.date = {
      from: detail.value,
      to: detail.value
    };

    this.selectionChanged.emit(this.date);
  }

}
